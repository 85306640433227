import { Builder } from '@builder.io/react'
import { BuildHighlight } from './BuildHighlight'

Builder.registerComponent(BuildHighlight, {
  name: 'Build Highlight',
  inputs: [
    {
      name: 'tabs',
      type: 'list',
      subFields: [
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
        },
        {
          name: 'centerImage',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
        },
        { name: 'title', type: 'string', required: true },
        { name: 'header', type: 'richText', required: true },
        { name: 'description', type: 'richText', required: true },
        { name: 'link', type: 'url', required: true },
        { name: 'price', type: 'string', required: true },
        {
          name: 'features',
          type: 'list',
          subFields: [
            {
              name: 'image',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
              required: true,
            },
            { name: 'title', type: 'string', required: true },
            { name: 'price', type: 'string', required: true },
          ],
        },
      ],
      defaultValue: [
        {
          image: 'https://via.placeholder.com/150',
          title: 'Base Kit',
          header: 'Designed for customization.</br>Built for adventure.',
          description: 'A modular design system that evolves as your journey takes new turns.',
          link: '#',
          price: '$18,999',
          centerImage: 'https://via.placeholder.com/600x300',
          features: [
            { image: 'https://via.placeholder.com/150', title: 'EcoFlow Power', price: '+ $3,395' },
            { image: 'https://via.placeholder.com/150', title: 'Omega Kitchen', price: '+ $4,495' },
          ],
        },
        {
          image: 'https://via.placeholder.com/150',
          title: 'Quest Camper',
          description: 'Quest Camper Description',
          link: '#',
          price: '$35,999',
          centerImage: 'https://via.placeholder.com/600x300',
          features: [
            { image: 'https://via.placeholder.com/150', title: 'Upper Cabinet', price: '+ $1,339' },
            {
              image: 'https://via.placeholder.com/150',
              title: 'Folding Queen Bed',
              price: '+ $3,780',
            },
          ],
        },
        // Add more tabs as needed
      ],
    },
  ],
})
