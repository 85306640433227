import { Builder, builder } from "@builder.io/react"
import builderConfig from "@config/builder"

import "../blocks/KlaviyoFlyoutButton"
import "../blocks/ProductGrid/ProductGrid.builder"
import "../blocks/CollectionView/CollectionView.builder"
import "../blocks/IntraPageNavBar/IntraPageNavBar.builder"
import "@components/common/ProductCta"

import "@components/MuxVideoPlayer/MuxVideoPlayer.builder"
import "@components/EyeCandy/EyeCandy.builder"

// tailwind ui components
import "@components/BaseModalWithChildren/BaseModalWithChildren.builder"

// Build Highlight
import "@components/BuildHighlight/BuildHighlight.builder"

// Features
import "@components/ProductFeaturesWithTwoImages/ProductFeaturesWithTwoImages.builder"
import "@components/ProductFeaturesWithFadingImage/ProductFeaturesWithFadingImage.builder"
import "@components/ProductFeaturesWithAlternatingImage/ProductFeaturesWithAlternatingImage.builder"
import "@components/ProductFeaturesWithSquareImages/ProductFeaturesWithSquareImages.builder"
import "@components/ProductFeaturesWithTieredImages/ProductFeaturesWithTieredImages.builder"
import "@components/ProductFeaturesWithTabs/ProductFeaturesWithTabs.builder"
import "@components/ProductFeaturesWithImage/ProductFeaturesWithImage.builder"
import "@components/ProductFeaturesWithCenteredImage/ProductFeaturesWithCenteredImage.builder"

// Marketing Sections
import "@components/MarketingSectionsWithBackgroundImageAndOverlappingCards/MarketingSectionsWithBackgroundImageAndOverlappingCards.builder"
import "@components/MarketingSectionsOptinParagraphOnDark/MarketingSectionsOptinParagraphOnDark.builder"
import "@components/MarketingSectionsOptinParagraphOnGray/MarketingSectionsOptinParagraphOnGray.builder"
import "@components/CenteredAccordionWithTitle/CenteredAccordionWithTitle.builder"
import "@components/ContactSectionsSplitTwoTone/ContactSectionsSplitTwoTone.builder"

// Pricing Sections
import "@components/PricingSectionsSingleWithFeatures/PricingSectionsSingleWithFeatures.builder"
import "@components/PricingSectionsWithComparisonTable/PricingSectionsWithComparisonTable.builder"
import "@components/PricingSectionsThreeTiers/PricingSectionsThreeTiers.builder"
import "@components/PricingSectionsSingleWithFeaturesWithBuyButton/PricingSectionsSingleWithFeaturesWithBuyButton.builder"

// Footers
import "@components/FooterSimpleCentered/FooterSimpleCentered.builder"

// Incentives
import "@components/IncentiveThreeColumnWithSplitHeader/IncentiveThreeColumnWithSplitHeader.builder"
import "@components/IncentiveFourColumnWithIllustrations/IncentiveFourColumnWithIllustrations.builder"
import "@components/IncentiveThreeColumnWithHeader/IncentiveThreeColumnWithHeader.builder"

// Promos
import "@components/PromoWithFadingImageAndTestimonials/PromoWithFadingImageAndTestimonials.builder"
import "@components/PromoFullWidthWithBackgroundImage/PromoFullWidthWithBackgroundImage.builder"
import "@components/PromoWithOfferAndSplitImage/PromoWithOfferAndSplitImage.builder"

// Stats sections
import "@components/stats-sections/SplitWithImage/SplitWithImage.builder"

// Team Sections
import "@components/team-sections/WithImageAndShortParagraph/WithImageAndShortParagraph.builder"

// Testimonial sections
import "@components/testimonials/SimpleCentered/SimpleCentered.builder"

// Blog sections
import "@components/blog/ThreeColumnBlogArticles/ThreeColumnBlogArticles.builder"
import "@components/blog/BlogComponent/BlogComponent.builder"

Builder.register("insertMenu", {
  name: "General",
  items: [
    { name: "Custom Launch modal" },
    { name: "Mux video player" },
    { name: "Eye candy" },
    { name: "Klaviyo flyout button" },
    { name: "IntraPageNavBar" },
  ],
})
Builder.register("insertMenu", {
  name: "Website Redesign 2024",
  items: [
    { name: "Build Highlight" },
    { name: "Blog Component" },
    { name: "Marketing sections opt in paragraph on gray" },
  ],
})
Builder.register("insertMenu", {
  name: "Product Features",
  items: [
    { name: "Product Features w/ header, two images, and description" },
    { name: "Product Features w/ fading image and specs" },
    { name: "Product Features alternating image/description" },
    { name: "Product Features with square images" },
    { name: "Product Features with tiered images" },
    { name: "Product Features w/ tabs and features" },
    { name: "Product Features w/ image" },
    { name: "Product Features w/ centered image" },
  ],
})
Builder.register("insertMenu", {
  name: "Incentives",
  items: [
    { name: "3-column with illustrations and split header" },
    { name: "4-column with illustrations" },
    { name: "3-column with illustrations and header" },
  ],
})

Builder.register("insertMenu", {
  name: "Promo Sections",
  items: [
    { name: "With fading background image and testimonials" },
    { name: "Full-width with background image" },
    { name: "With offers and split image" },
  ],
})

Builder.register("insertMenu", {
  name: "Marketing Sections",
  items: [
    { name: "Marketing sections w/ bg image + overlapping cards" },
    { name: "Marketing sections opt in paragraph on dark" },
    { name: "Centered Accordion" },
    { name: "Contact sections split two-tone" },
  ],
})

Builder.register("insertMenu", {
  name: "Pricing Sections",
  items: [
    { name: "Single price w/ feature list" },
    { name: "Single price w/ feature list w/ buy button" },
    { name: "Comparison table" },
    { name: "Three tiers" },
  ],
})

Builder.register("insertMenu", {
  name: "Stats sections",
  items: [{ name: "split with image - stats sections" }],
})

Builder.register("insertMenu", {
  name: "Team sections",
  items: [{ name: "with image and short paragraph - team sections" }],
})

Builder.register("insertMenu", {
  name: "Testimonial sections",
  items: [{ name: "simple centered - testimonials" }],
})

Builder.register("insertMenu", {
  name: "Footers",
  items: [{ name: "Footer Simple Centered" }],
})

Builder.register("insertMenu", {
  name: "Blog",
  items: [{ name: "Three column blog layout" }],
})

export { builder, Builder }
