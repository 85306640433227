import { Builder } from '@builder.io/react'
import { MarketingSectionsOptinParagraphOnGray } from './MarketingSectionsOptinParagraphOnGray'

Builder.registerComponent(MarketingSectionsOptinParagraphOnGray, {
  name: 'Marketing sections opt in paragraph on gray',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Sign up for our newsletter',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue: 'Be the first to find out about our events, deals, and latest products!',
    },
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'Subscribe',
    },
    {
      name: 'klaviyoListId',
      type: 'string',
      defaultValue: 'Ujm6sD',
    },
  ],
})
