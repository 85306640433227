import { Builder } from '@builder.io/react'
import { BlogComponent } from './BlogComponent'

Builder.registerComponent(BlogComponent, {
  name: 'Blog Component',
  inputs: [
    {
      name: 'postReferences',
      type: 'list',
      subFields: [
        {
          name: 'post',
          type: 'reference',
          model: 'blog-post',
        },
      ],
      defaultValue: [
        {
          post: {
            '@type': '@builder.io/core:Reference',
            id: 'bee0a599d28b4fe1bc93a576f1cf490b',
            model: 'blog-post',
          },
        },
        {
          post: {
            '@type': '@builder.io/core:Reference',
            id: 'f745ce42eca245f5b35ce08ea46bd96f',
            model: 'blog-post',
          },
        },
        {
          post: {
            '@type': '@builder.io/core:Reference',
            id: '8b584e1d0cf145cf86c515278201504c',
            model: 'blog-post',
          },
        },
        // More posts...
      ],
    },
  ],
})
