import React, { FC, useMemo } from "react"

export interface State {
  displaySidebar: boolean
  navigationLinks?: Array<{ link: string; title: string }>
  navBar?: any
  logo?: { image?: string; text: string; width: number; height: number }
  toggleSidebar?: any
  closeSidebar?: any
  openSidebar?: any
}

const initialState = {
  displaySidebar: false,
}

type Action =
  | {
      type: "OPEN_SIDEBAR"
    }
  | {
      type: "CLOSE_SIDEBAR"
    }

export const UIContext = React.createContext<State>(initialState)

UIContext.displayName = "UIContext"
interface UIProviderProps {
  siteSettings: Partial<State>
  navBar?: Partial<State>
  children?: React.ReactNode
}

export const UIProvider = ({ siteSettings, navBar, children }: UIProviderProps) => {
  const [state, setState] = React.useState({
    ...initialState,
    ...siteSettings,
    navBar,
  })

  const openSidebar = () => setState((prev) => ({ ...prev, displaySidebar: true }))
  const closeSidebar = () => setState((prev) => ({ ...prev, displaySidebar: false }))
  const toggleSidebar = () =>
    setState((prev) => ({ ...prev, displaySidebar: !prev.displaySidebar }))

  const value = {
    ...state,
    ...siteSettings,
    navBar,
    openSidebar,
    closeSidebar,
    toggleSidebar,
  }

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}

export const useUI = () => {
  const context = React.useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

export const ManagedUIContext = ({ children, siteSettings, navBar }: UIProviderProps) => (
  <UIProvider siteSettings={siteSettings} navBar={navBar}>
    {children}
  </UIProvider>
)
