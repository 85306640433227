import React, { FC, useState, useEffect, useContext } from "react"
import Link from "next/link"
import { UserNav } from "@components/common"
import env from "@config/env"
import { BuilderComponent, builder } from "@builder.io/react"
import { useCart } from "@lib/swell/storefront-data-hooks"
import { useThemeUI } from "theme-ui"
import { useUI } from "@components/ui/context"
import Image from "next/image"
import Searchbar from "./Searchbar"
import { useRouter } from "next/router"
import { sidebarContext } from "@components/common/Layout"

import { Fragment } from "react"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { Bars3Icon, XMarkIcon, ShoppingCartIcon } from "@heroicons/react/24/outline"
import { MobileSearch, Search, MinimalSearch } from "@components/AlgoliaSearch"

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/react"
import { MagnifyingGlassIcon, ShoppingBagIcon, UserIcon } from "@heroicons/react/24/outline"

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ")
}
const ShoppingCartButton = () => {
  const cart = useCart()
  const { setOpenSidebar } = useContext(sidebarContext)

  return (
    <div className="flex flex-1 items-center justify-end">
      <button
        onClick={(e) => setOpenSidebar(true)}
        className="group -m-2 flex items-center rounded-md p-2 text-gray-800"
      >
        <ShoppingCartIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />
        <span className="ml-2 text-sm font-medium">{cart?.items?.length}</span>
        <span className="sr-only">items in cart, view bag</span>
      </button>
    </div>
  )
}

const Navbar: FC = () => {
  const [announcement, setAnnouncement] = useState()
  const { theme } = useThemeUI()
  const { navigationLinks, logo, navBar } = useUI()
  const cart = useCart()
  const router = useRouter()
  const [open, setOpen] = useState(false)

  function active(link: string) {
    if (link === router.asPath) return true
    return false
  }

  useEffect(() => {
    let isSubscribed = true
    async function fetchContent() {
      const items = cart?.items || []
      const anouncementContent = await builder
        .get("announcement-bar", {
          cachebust: env.isDev,
          userAttributes: {
            itemInCart: items.map((item: any) => item.product.slug),
          } as any,
        })
        .toPromise()
      isSubscribed ? setAnnouncement(anouncementContent) : null
    }

    fetchContent()
    // cancel subscription to useEffect
    return () => {
      isSubscribed = false
    }
  }, [cart?.items])

  return (
    <React.Fragment>
      <div className="fixed top-4 left-0 right-0 z-30">
        {/* Mobile Menu */}
        <Dialog open={open} onClose={setOpen} className="relative z-40 lg:hidden">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 z-40 flex">
            <DialogPanel
              transition
              className="relative flex w-full transform flex-col overflow-y-auto bg-white pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              {/* Mobile - Close Menu */}
              <div className="flex px-4 pb-2 pt-5">
                <div className="flex-1 flex justify-start items-center">
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="-m-2 inline-flex rounded-md p-2 text-gray-400"
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 stroke-black" />
                  </button>
                </div>
                <div className="flex-1 flex justify-center items-center">
                  {navBar && navBar.logo && (
                    <Link href="/">
                      <img className="h-12 w-auto" src={navBar.logo} alt="New Way Van Life" />
                    </Link>
                  )}
                </div>

                <div className="flex-1 flex justify-end items-center">
                  <div className="flex space-x-2">
                    <MinimalSearch />
                    <ShoppingCartButton />
                  </div>
                </div>
              </div>

              <TabGroup className="mt-2 flex-1 overflow-y-auto">
                {/* Mobile Menu - Categories */}
                <div>
                  <TabList className="-mb-px flex space-x-2 px-4">
                    {navBar.categories.map((category: any) => (
                      <Tab
                        key={category.name}
                        className="flex-1 whitespace-nowrap border px-1 py-4 font-semibold text-gray-900 data-[selected]:text-white data-[selected]:bg-black data-[open]:text-white data-[open]:bg-black rounded-full my-3 hover:text-white text-sm"
                      >
                        {category.name}
                      </Tab>
                    ))}
                  </TabList>
                </div>
                <TabPanels as={Fragment}>
                  {navBar.categories.map((category: any) => (
                    <TabPanel key={category.name} className="space-y-10 px-4 pb-8 pt-10">
                      {/* Mobile Menu - Sections */}
                      {category.sections.map((section: any, columnIdx: number) => (
                        <div key={columnIdx} className="space-y-10">
                          <div key={section.name}>
                            <p
                              id={`${category.id}-${section.id}-heading-mobile`}
                              className="font-medium text-gray-900 font-mono"
                            >
                              {section.name}
                            </p>
                            <ul
                              role="list"
                              aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                              className="mt-6 flex flex-col space-y-6"
                            >
                              {section.items.map((item: any) => (
                                <li key={item.name} className="flow-root">
                                  <a href={item.href} className="-m-2 block p-2 text-2xl">
                                    {item.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}

                      <hr />

                      {/* Mobile Menu - Links */}
                      <div>
                        {category.links.map((item: any, itemIdx: number) => (
                          <div key={itemIdx} className="">
                            <a href={item.link} className="-m-2 block p-2 text-gray-500 text-lg">
                              {item.text}
                            </a>
                          </div>
                        ))}
                      </div>
                    </TabPanel>
                  ))}
                </TabPanels>
              </TabGroup>

              {/* Sticky Footer */}
              <div className="sticky bottom-0 bg-white px-4 py-6 shadow-md">
                <Link
                  href="/configure/sprinter-van-builder-144"
                  className="bg-black text-white px-6 py-4 items-center gap-x-1.5 rounded-full sm:hidden text-sm font-semibold"
                >
                  Build Your Van
                </Link>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        <header className={`relative bg-white mx-4 border border-gray-200 rounded-lg`}>
          <nav aria-label="Top" className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="">
              <div className="flex h-16 items-center justify-between">
                {/* Mobile Menu - Open Menu Button */}
                <div className="flex flex-1 items-center lg:hidden">
                  <button
                    type="button"
                    onClick={() => setOpen(true)}
                    className="-ml-2 rounded-md bg-white p-2 text-gray-400"
                  >
                    <span className="sr-only">Open menu</span>
                    <Bars3Icon aria-hidden="true" className="h-6 w-6 stroke-black" />
                  </button>
                </div>

                {/* Flyout menus */}
                <PopoverGroup className="hidden lg:block lg:flex-1 lg:self-stretch">
                  <div className="flex h-full space-x-8">
                    {navBar.categories.map((category: any) => (
                      <Popover key={category.name} className="flex">
                        {({ open }) => {
                          return (
                            <>
                              <div className="relative flex">
                                <PopoverButton className="group relative z-10 flex items-center justify-center text-sm font-semibold text-gray-900 transition-colors duration-200 ease-out data-[open]:text-white data-[open]:bg-black rounded-full p-4 hover:bg-black my-3 hover:text-white">
                                  {category.name}
                                </PopoverButton>
                              </div>
                              <PopoverPanel
                                transition
                                className="absolute z-20 inset-x-0 top-full transition data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in border border-gray-200"
                              >
                                {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                <div
                                  aria-hidden="true"
                                  className="absolute inset-0 top-1/2 bg-white shadow"
                                />

                                <div className="relative bg-white">
                                  <div className="mx-auto max-w-7xl px-8">
                                    <div className="grid grid-cols-7 gap-x-8 gap-y-10 py-16">
                                      {/* Sections */}
                                      <div className="grid col-span-2 grid-cols-1 gap-x-8 gap-y-10 text-sm text-gray-500">
                                        {category.sections.map(
                                          (section: any, columnIdx: number) => (
                                            <div key={columnIdx} className="space-y-10">
                                              <div key={section.name}>
                                                <p
                                                  id={`${category.id}-${section.id}-heading`}
                                                  className="font-medium font-mono text-gray-900"
                                                >
                                                  {section.name}
                                                </p>
                                                <ul
                                                  role="list"
                                                  aria-labelledby={`${category.id}-${section.id}-heading`}
                                                  className="mt-4 space-y-4"
                                                >
                                                  {section.items.map((item: any) => (
                                                    <li key={item.name} className="flex">
                                                      <a
                                                        href={item.href}
                                                        className="hover:text-gray-500 text-2xl text-gray-900"
                                                      >
                                                        {item.name}
                                                      </a>
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            </div>
                                          )
                                        )}

                                        <hr />

                                        {/* Links */}
                                        <div>
                                          {category.links.map((item: any, itemIdx: number) => (
                                            <div key={itemIdx}>
                                              <a
                                                href={item.link}
                                                className="-m-2 block p-2 text-gray-500"
                                              >
                                                {item.text}
                                              </a>
                                            </div>
                                          ))}
                                        </div>
                                      </div>

                                      {/* Desktop Menu - Featured Tiles */}
                                      <div className="grid col-span-5 grid-cols-3 grid-rows-1 gap-8 text-sm">
                                        {category.featured.map((item: any, itemIdx: number) => (
                                          <div
                                            key={item.name}
                                            className={classNames(
                                              itemIdx === 0 ? "aspect-w-2 col-span-2" : "",
                                              "group aspect-h-1 aspect-w-1 relative overflow-hidden rounded-md bg-gray-100"
                                            )}
                                          >
                                            <img
                                              alt={item.imageAlt}
                                              src={item.image}
                                              className="object-cover object-center group-hover:opacity-75"
                                            />
                                            <div className="flex flex-col justify-end">
                                              <div className="bg-white bg-opacity-60 p-4 text-sm">
                                                <a
                                                  href={item.href}
                                                  className="font-medium text-gray-900"
                                                >
                                                  <span
                                                    aria-hidden="true"
                                                    className="absolute inset-0"
                                                  />
                                                  {item.name}
                                                </a>
                                                <p
                                                  aria-hidden="true"
                                                  className="mt-0.5 text-gray-700 sm:mt-1"
                                                >
                                                  {item.linkText}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </PopoverPanel>
                            </>
                          )
                        }}
                      </Popover>
                    ))}
                  </div>
                </PopoverGroup>

                {/* Logo */}
                {navBar && navBar.logo && (
                  <Link href="/">
                    <img className="h-12 w-auto" src={navBar.logo} alt="New Way Van Life" />
                  </Link>
                )}

                {/* Search + Shopping Cart */}
                <div className="flex flex-1 items-center justify-end">
                  <div className="flex items-center justify-end space-x-4">
                    <Link
                      href="/configure/sprinter-van-builder-144"
                      className="bg-black text-white px-4 py-2 items-center gap-x-1.5 rounded-full text-xs hidden sm:inline-flex sm:text-sm font-semibold"
                    >
                      Build Your Van
                    </Link>
                    <div className="hidden lg:block">
                      <MinimalSearch />
                    </div>

                    {/* <SearchDialog className="hidden lg:block" {...dialogProps} /> */}
                    <div className="hidden lg:block">
                      <ShoppingCartButton />
                    </div>
                  </div>

                  <div className="flex space-x-2 lg:hidden">
                    <div className="ml-2 p-2 text-gray-400 hover:text-gray-500">
                      <span className="sr-only">Search</span>
                      <MinimalSearch />
                    </div>

                    {/* Mobile menu button */}
                    <ShoppingCartButton />
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </React.Fragment>
  )
}

export default Navbar
